import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseFormComponent } from '../shared/base-form/base-form.component';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends BaseFormComponent implements OnInit {

  private static _ID = 0;
  private _uniqueID = '__InputComponent_' + (TextareaComponent._ID++);

  private _readonly: boolean;
  private _disabled: boolean;
  private _labelless: boolean;
  private _narrow: boolean;
  private _placeholder: string;


  @HostBinding('class')
  private get classes() {
    const arr = ['app-textarea'];

    arr.push('app-textarea-' + (this.formControl?.untouched ? 'untouched' : 'touched'));
    arr.push('app-textarea-' + (this.formControl?.dirty ? 'dirty' : 'pristine'));
    arr.push('app-textarea-' + (this.formControl?.valid ? 'valid' : 'invalid'));

    if (this.hasRequiredValidator) {
      arr.push('app-textarea-required');
    }
    if (this.narrow) {
      arr.push('app-textarea-narrow');
    }

    if (!this.value) {
      arr.push('app-textarea-empty');
    }

    if (this.disabled) {
      arr.push('app-textarea-disabled');
    }

    arr.push('app-input-align-' + this.align);

    return arr;
  }

  get uniqueID(): string {
    return this._uniqueID;
  }

  @Input()
  set value(value: string) {
    if (this.value !== value) {
      this.formControl.setValue(value);
    }
  }

  get value(): string {
    return this.formControl.value as string;
  }

  @Output()
  readonly valueChange = this.formControl.valueChanges;

  @Input()
  set readonly(value: boolean) {
    this._readonly = value;
  }

  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set labelless(value: boolean) {
    this._labelless = value;
  }

  get labelless(): boolean {
    return this._labelless;
  }

  @Input()
  set narrow(value: boolean) {
    this._narrow = value;
  }

  get narrow(): boolean {
    return this._narrow;
  }

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
  }

  get placeholder(): string {
    return this._placeholder || '';
  }

  @Input()
  rows: number = 5;

  @Output()
  readonly focus = new EventEmitter<FocusEvent>();

  @Output()
  readonly blur = new EventEmitter<FocusEvent>();

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

}
