<div class="textarea-label-container">
  <label [attr.for]="uniqueID">{{label}}</label>
</div>

<textarea
  autocomplete="off"
  class="app-textarea-value"
  [attr.rows]="rows"
  [attr.id]="uniqueID"
  [placeholder]="placeholder"
  [formControl]="formControl"
  [attr.readonly]="readonly"
  [attr.disabled]="disabled"
></textarea>

<ng-container *ngIf="errorMessage$ | async as message">
  <div class="app-textarea-error-warning">{{message}}</div>
</ng-container>
